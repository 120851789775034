import dayjs from 'dayjs';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  getCities,
  getCountries,
  getCountryCode,
  getCountryData,
  getCountryShieldSvgUrl,
} from '../api/fetchDataFromCDN';
import { City, Country, CountryData } from '../types/game';

interface ITodaysCountryContext {
  todaysCountry: CountryData;
  countryList: Country[];
  cityList: City[];
  todaysCity?: City | null;
  rounds: IBonusRound[];
}

export const TodaysCountryContext = createContext<ITodaysCountryContext | null>(
  null,
);

export interface IBonusRound {
  route: string;
  name: string;
}

const BONUS_ROUND_LIST: IBonusRound[] = [
  {
    route: '/bonus-round/1',
    name: 'Pick the country shape',
  },
  {
    route: '/bonus-round/2',
    name: 'Pick the National Emblem Of the Country',
  },
  {
    route: '/bonus-round/3',
    name: 'Pick the flag of the Capital',
  },
  {
    route: '/bonus-round/4',
    name: 'Pick the flag of a neighbouring country',
  },
  {
    route: '/bonus-round/5',
    name: 'Population & Currency',
  },
];

export const TodaysCountryProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [todaysCountry, setTodaysCountry] = useState<CountryData>({
    code: '',
    latitude: 0,
    longitude: 0,
    name: '',
    names: {},
    flags: [],
    continent: [],
    population: 0,
    currencyData: {
      code: '',
      name: '',
      nameChoices: [],
    },
    borders: [],
    borderMode: 'nearby',
    autoUpdateBorders: false,
    size: 0,
    languageData: {
      languageSources: [],
      languages: [],
    },
    links: [],
  });

  const [countryList, setCountryList] = useState<Country[]>([]);

  const [cityList, setCityList] = useState<City[]>([]);

  const todaysCity = useMemo(
    () =>
      cityList.filter(
        (c) => c.countryCode === todaysCountry.code && c.capital,
      )[0],
    [cityList, todaysCountry],
  );

  const [rounds, setRounds] = useState<IBonusRound[]>(BONUS_ROUND_LIST);

  useEffect(() => {
    const fetchTodaysCountry = async () => {
      const dayString = dayjs(Date.now()).format('YYYY-MM-DD');
      const countryCode = await getCountryCode(dayString);
      const countryData = await getCountryData(countryCode);
      setTodaysCountry(countryData);
    };
    const fetchCountryList = async () => {
      const countryList = await getCountries();
      setCountryList(countryList);
    };
    const fetchCityList = async () => {
      const cityList = await getCities();
      setCityList(cityList);
    };
    fetchTodaysCountry();
    fetchCountryList();
    fetchCityList();
  }, []);

  useEffect(() => {
    if (!todaysCountry.code || !countryList.length || !cityList.length) return;
    const list = [...BONUS_ROUND_LIST];

    if (!todaysCity?.flag) {
      const index = list.findIndex((r) => r.route === '/bonus-round/3');
      if (index >= 0) {
        list.splice(index, 1);
      }
    }

    setRounds(list);

    // check emblem exists
    const checkEmblem = async () => {
      const shieldUrl = getCountryShieldSvgUrl(todaysCountry.code);
      const response = await fetch(shieldUrl);
      if (response.headers.get('content-type') === 'image/svg+xml') return;

      setRounds((prev) => {
        const newList = [...prev];
        const index = newList.findIndex((r) => r.route === '/bonus-round/2');
        if (index >= 0) {
          newList.splice(index, 1);
        }
        return newList;
      });
    };
    checkEmblem();
  }, [
    cityList.length,
    countryList.length,
    todaysCity?.flag,
    todaysCountry.code,
  ]);

  if (!todaysCountry.name || !countryList.length) return null;

  return (
    <TodaysCountryContext.Provider
      value={{ todaysCountry, countryList, cityList, todaysCity, rounds }}
    >
      {children}
    </TodaysCountryContext.Provider>
  );
};

export const useTodaysCountry = () => {
  const context = useContext(TodaysCountryContext);
  if (!context) {
    throw new Error(
      'useTodaysCountry must be used within a TodaysCountryProvider',
    );
  }
  return context;
};
