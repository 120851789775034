import { getTheme } from './getTheme';

const DIRECTION_ARROWS = {
  N: '⬆️',
  NNE: '↗️',
  NE: '↗️',
  ENE: '↗️',
  E: '➡️',
  ESE: '↘️',
  SE: '↘️',
  SSE: '↘️',
  S: '⬇️',
  SSW: '↙️',
  SW: '↙️',
  WSW: '↙️',
  W: '⬅️',
  WNW: '↖️',
  NW: '↖️',
  NNW: '↖️',
};

export function getDirectionEmoji(guess: {
  direction: keyof typeof DIRECTION_ARROWS;
  distance: number;
}) {
  return guess.distance === 0 ? '🎉' : DIRECTION_ARROWS[guess.direction];
}

const MAX_DISTANCE_ON_EARTH = 20_000_000;

export function computeProximityPercent(distance: number): number {
  const proximity = Math.max(MAX_DISTANCE_ON_EARTH - distance, 0);
  return Math.floor((proximity / MAX_DISTANCE_ON_EARTH) * 100);
}

export function generateSquareCharacters(distance: number): string[] {
  const characters = new Array<string>(5);
  const proximity = computeProximityPercent(distance);
  const greenSquareCount = Math.floor(proximity / 20);
  const yellowSquareCount = proximity - greenSquareCount * 20 >= 10 ? 1 : 0;

  characters.fill('🟩', 0, greenSquareCount);
  characters.fill('🟨', greenSquareCount, greenSquareCount + yellowSquareCount);
  characters.fill(
    getTheme() === 'light' ? '⬜' : '⬛',
    greenSquareCount + yellowSquareCount,
  );

  return characters;
}
