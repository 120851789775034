import React from 'react';
import { Link } from 'react-router-dom';

import EmojiRender from './EmojiRender';

export const SettingsLinkIcon: React.FC = () => {
  return (
    <Link to="/settings">
        <EmojiRender text="⚙️" className='mr-3 ml-3 text-xl'/>      
    </Link>
  );
};
