import React from 'react';
import { Link } from 'react-router-dom';

import EmojiRender from './EmojiRender';

export const TeuteufGamesLinkIcon: React.FC = () => {
  return (
    <Link to="/teuteuf-games">
      <EmojiRender text="🎮" className="text-xl" />
    </Link>
  );
};
