import {
  InteractiveToast,
  InteractiveToastProps,
} from '@pla324/teuteuf-interactive-toast';

interface IFrameWarningToastProps {
  show?: InteractiveToastProps['show'];
}

export const IFrameWarningToast = ({ show }: IFrameWarningToastProps) => {
  return (
    <InteractiveToast
      title="Error!"
      body={
        "Flagle's official website is https://www.flagle.io/ It looks like you're playing on a website that has stolen it! These websites steal revenue from game creators by re-hosting their games and overlaying their own ads. Play Flagle on flagle.io, and you'll always have the latest version, fewer ads and help support the creators (i.e. us)! Thanks, - Teuteuf Team"
      }
      primaryButton={{
        label: 'Go There',
        onClick: () =>
          window.open('https://www.flagle.io/?utm_campaign=iframe_popup'),
      }}
      show={show}
    />
  );
};
